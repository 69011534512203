import './App.css';
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import CopyrightIcon from '@material-ui/icons/Copyright';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import Email from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {NavLink} from 'react-router-dom';
import './footer.css'

function Footer() {
  return (
    <>
    <div className="App-footer w-100">
        <Row className="footer-one">
          <Col lg={3} xs={12} sm={6} className="d-block mt-2">
          
          <ul className="footer-list flex-footer-list">
            <NavLink className='footer-li pr-1' to="/">HOME </NavLink>{" | "}
            <NavLink className='footer-li pr-1 pl-1' to="/about"> ABOUT US </NavLink> {" | "}
            <NavLink className='footer-li pr-1 pl-1' to="/books"> BOOK </NavLink> {" | "}
            <NavLink className='footer-li pl-1' to="/contact"> CONTACT US </NavLink> 
        </ul> 
        
        <div className='text-left  ml-5 footer-connect'>
        <div className="mb-3">
        <a style={{padding:0, textDecoration : 'none', color: 'inherit'}} href='tel:+91 9967490255'>
        <PermPhoneMsgIcon fontSize="default" style={{color: "#fff"}} />
        <span> +91 99674 90255 </span>
        </a>
        </div>
        <a style={{padding:0, textDecoration : 'none', color: 'inherit'}} href='mailto:contact@droneschoolindia.com'>
        <Email fontSize="default" style={{color: "#fff" }}/>
        <span> contact@droneschoolindia.com </span> 
        </a>  
        </div>
          </Col>

          <Col lg={3} xs={12} sm={6}>
          <h4> SERVICES</h4>
          <ul className="footer-list">
            <NavLink className='footer-li d-block' to="/aerial-cinematography"> Aerial Cinematics</NavLink>
            <NavLink className='footer-li d-block' to="/virtual-tours"> 360° Virtual Tours </NavLink>
            <NavLink className='footer-li d-block'  to="/surveying"> Aerial Surveying & Mapping  </NavLink>
            {/* <NavLink className='footer-li d-block'  to="/drone-geotagged-video-road-survey">Road Survey -  Geotagged Video </NavLink> */}
        </ul>
          </Col>

          <Col lg={3} xs={12} sm={6}>
          <h4> COURSES </h4>
          <b> ONLINE </b>
          <ul className="footer-list">
          <NavLink className='footer-li d-block' to={{pathname:"https://courses.droneschoolindia.com/courses/Specialization-Geotagging-Drone-Videos-for-Survey-66e7d91056027e2eb497ea35-66e7d91056027e2eb497ea35"}} target="_blank">
          Geotagging Drone Videos for Survey
          </NavLink>
          <NavLink className='footer-li d-block' to={{pathname:"https://courses.droneschoolindia.com/courses/Drone-Technology-for-Beginners-67a9a1bafda1f007d4497a31"}} target="_blank">
          Drone Technology for Beginners
          </NavLink>
          </ul>
          <b> OFFLINE </b>
          <ul className="footer-list">
            <NavLink className='footer-li d-block' to="/aerial-robotics-workshop">  Aerial Robotics - Junior</NavLink>
            <NavLink className='footer-li d-block' to="/drone-coding-bootcamp"> Drone Coding Bootcamp</NavLink>
            <NavLink className='footer-li d-block'  to="/aerial-filming-course">Aerial Filming</NavLink>
          </ul>
          </Col>

          <Col lg={3} xs={12} sm={6}>
            <h4> PORTFOLIO</h4>
            <ul className="footer-list">
              <NavLink className='footer-li d-block' to="/aerial-video-gallery"> Showreels </NavLink>
              <NavLink className='footer-li d-block' to="/aerial-photo-gallery"> Aerial Photography </NavLink>
              <NavLink className='footer-li d-block' to="/aerial-360-virtual-tour-gallery"> 360° Virtual Tours </NavLink>
            </ul>
          </Col>
          <Row className="social-row">
          <Col>
            <div className="social-container">
            <a href="https://www.youtube.com/channel/UCNt-Sfhj3a7i2pqtS2iChNQ"
              className="youtube social">
              <YouTubeIcon fontSize="large"/>
            </a>
            <a href="https://www.facebook.com/droneschoolindia"
              className="facebook social">
              <FacebookIcon fontSize="large" />
            </a>
            <a href="https://www.linkedin.com/company/drone-school-india" className="linkedin social">
              <LinkedInIcon fontSize="large" />
            </a>
            <a href="https://www.instagram.com/droneschoolindia/"
              className="instagram social">
              <InstagramIcon fontSize="large" />
            </a>
        </div>
      </Col>
      </Row>
        </Row>
        <Row className="footer-two">
          <Col>
            <p><CopyrightIcon /> 2020 Drone School India. All Rights Reserved.</p>
          </Col>
        </Row>
    </div>
  
  </>
  );
}

export default Footer;
