import './App.css';
import React from 'react';
import './home.css';
import './services.css';
import {Card, Container, Col, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import Pagoda from './images/webp/Pagoda.webp';
import SurveyingPhoto from './images/webp/surveying_img.webp';
import RoadSurveyPhoto from './images/webp/geotagged-thumbnail.jpg';
import AerialRobotics from './images/webp/aerialRobotics.webp';
import filmingPhoto from './images/webp/af-img3.webp';
import Bootcamp from './images/webp/bootcamp.webp';
import HeaderUnderlineAnimation from './HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import ButtonAnimation from './ButtonAnimation/ButtonAnimation';
import Book_iso from './images/webp/book-iso.png';
import GeotaggingCourse from './images/webp/geotagging_course_thumbnail.webp';
import PlutoOnlineCourse from './images/webp/pluto-online-course-thumbnail.png';

function Services() {

  return (
    <>
     
    <Container>
    <div className='homeContactDiv'>
    
    <Row>
      <Col className='homeContactText mr-auto' xs={12} lg={12} >
        <h2 className='lightHeading'>What We Offer</h2>
        <h2 className='darkHeading'> Online Courses</h2><br /> 
      </Col>
    </Row>

    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <img className='img-fluid services-image' src={PlutoOnlineCourse} alt='Pluto Online Course Thumbnail'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>AERIAL ROBOTICS WORKSHOP</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation className='center' text="DRONE TECHNOLOGY FOR BEGINNERS" color="blue"> </HeaderUnderlineAnimation></Card.Title>
          
          <br />
          <Card.Text className="justify-content services-card-text">
          In this course, learners will explore the intricacies of drone technology, especially multiortor UAVs, understand their inner workings, movements, and controls, learn about different drone types and their unique features, study the aerodynamics behind their flight, and discover their applications across various fields.         
          Additionally, learn to assemble made in India Pluto nano drone and perform various flying activities.
          </Card.Text>
          
          <Card.Text className='services-card-list'>
            {/* <h5> COURSE OUTCOMES </h5> */}
            <ul> 
            <li> Duration: 3 hours</li>
            <li>Category: Drone Tinkering</li>
            <li>✔Pre-recorded Videos</li>
            <li>✔Self-paced Learning</li>
            <li>✔Quizzes Included</li>
            </ul>
          </Card.Text>
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          {/* <NavLink to="/drone-geotagged-video-road-survey" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink> */}
          <NavLink to={{pathname:"https://courses.droneschoolindia.com/courses/Drone-Technology-for-Beginners-67a9a1bafda1f007d4497a31"}} target="_blank" className='text-decoration-none'><ButtonAnimation text='ENROLL NOW' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>
    <br /> 

    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <img className='img-fluid services-image' src={GeotaggingCourse} alt='Geotagging Drone Video Thumbnail'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>AERIAL ROBOTICS WORKSHOP</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation className='center' text="GEOTAGGING DRONE VIDEOS FOR SURVEY" color="blue"> </HeaderUnderlineAnimation></Card.Title>
          
          <br />
          <Card.Text className="justify-content services-card-text">
          This course covers three main topics: fundamental concepts, techniques for data acquisition with drones, and post-processing to add geo-information or telemetry overlays to drone videos for the application of corridor survey such as highway construction demonstrated throughout this video series along with quizzes, tasks and project.          </Card.Text>

          <Card.Text className='services-card-list'>
            {/* <h5> COURSE OUTCOMES </h5> */}
            <ul> 
            {/* <li>Age Group : 9 to 15 years</li> */}
            <li> Duration: 12 hours</li>
            <li>Level: Intermediate</li>
            <li>Category: Application Based Learning </li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          <NavLink to="/drone-geotagged-video-road-survey" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          <NavLink to={{pathname:"https://courses.droneschoolindia.com/courses/Specialization-Geotagging-Drone-Videos-for-Survey-66e7d91056027e2eb497ea35-66e7d91056027e2eb497ea35"}} target="_blank" className='text-decoration-none'><ButtonAnimation text='ENROLL NOW' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>

    <br />  

    <Row>
      <Col className='homeContactText mr-auto' xs={12} lg={12} >
        <h2 className='lightHeading'>What We Offer</h2>
        <h2 className='darkHeading'> Our Books </h2><br /> 
      </Col>
    </Row>
    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
      <img className='img-fluid services-image' src={Book_iso} alt='Drone Book Isometric'></img>
    </Col>
    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>AERIAL CINEMATOGRAPHY</Card.Title> */}
          
          <h2><Card.Title className='services-card-title'><HeaderUnderlineAnimation text="About the Book" color="orange"> </HeaderUnderlineAnimation></Card.Title></h2>

          <br />
          <Card.Text className="justify-content services-card-text">
            This book covers essential concepts of drones, focusing on multirotor UAVs, and offers guided hands-on building steps, along with practical insights and instructions for piloting and mastering Pluto drones.
          </Card.Text>

          <Card.Text className='services-card-list'>
            {/* <h5> INDUSTRIES </h5> */}
            <ul> 
            <li> Authors: Dharna Nar and Radhika Kotecha</li>
            {/* <li>Publishers: Drone School India and Ane Books Pvt. Ltd.</li> */}
            </ul>
          </Card.Text>
          <br />

          <NavLink to="/books" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          <a href="https://amzn.in/d/0EauLBB" className='text-decoration-none'><ButtonAnimation text='Buy Now' color='black' bgColor='blue'/></a>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>


    <Row>
      <Col className='homeContactText mr-auto' xs={12} lg={12} >
        <h2 className='lightHeading'>What We Offer</h2>
        <h2 className='darkHeading'> Our Services </h2><br /> 
      </Col>
    </Row>

    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
      <img className='img-fluid services-image' src={Pagoda} alt='Pagoda'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>AERIAL CINEMATOGRAPHY</Card.Title> */}
          
          <h2><Card.Title className='services-card-title'><HeaderUnderlineAnimation text="Aerial Cinematics" color="orange"> </HeaderUnderlineAnimation></Card.Title></h2>

          <br />
          <Card.Text className="justify-content services-card-text">
          Showcase your projects with stunning high-quality shots and  stablizied footages from an aerial perspective. From covering events to building brand awareness digitally, the possibilities are limitless. Team up with us to explore!
          </Card.Text>

          <Card.Text className='services-card-list'>
            <h5> INDUSTRIES </h5>
            <ul> 
            <li><h2>- Films and Media </h2></li>
            <li><h2>- Real Estate</h2></li>
            {/* <li><h1>- Weddings and Outdoor Events</h1></li> */}
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button>
          <Button className='left'> SHOWREELS </Button> */}


          <NavLink to="/aerial-cinematography" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          <NavLink to="/aerial-video-gallery" className='text-decoration-none'><ButtonAnimation text='Showreels' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>

    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <iframe id="myPano" width="100%" height="500px" allowfullscreen src={"https://cdn.pannellum.org/2.5/pannellum.htm#panorama=https://i.imgur.com/djMA0hf.jpg&title=River%20Tales,%20Karjat&author=DSI%20-%20Aerial%20Cinematics&autoLoad=true&autoRotate=-2&yaw=0&pitch=-15&hfov=90"}></iframe>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>360° VIRTUAL TOURS</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation text="360° VIRTUAL TOURS" color="orange"> </HeaderUnderlineAnimation></Card.Title>

          <br />
          <Card.Text className="justify-content services-card-text">
          Teleport your customers by giving them a 360° virtual tour! Aerial panoramas provide the viewer with an immersive and interactive digital experience, thus influencing their decision-making process. Team up with us to make your vision a reality.
          </Card.Text>

          <Card.Text className='services-card-list'>
            <h5> INDUSTRIES </h5>
            <ul> 
            <li><h2>- Real Estate</h2></li>
            <li><h2>- Tours and Travel</h2></li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button>
          <Button className='left'> GALLERY </Button> */}

          <NavLink to="/virtual-tours" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          <NavLink to="/aerial-360-virtual-tour-gallery" className='text-decoration-none'><ButtonAnimation text='Gallery' color='black' bgColor='blue'/></NavLink>

          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>


    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
      <img className='img-fluid services-image' src={SurveyingPhoto} alt='Surveying'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>SURVEYING</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation text="DRONE SURVEY" color="orange"> </HeaderUnderlineAnimation></Card.Title>
          <br />
          <Card.Text className="justify-content services-card-text">
          Assess your next move with an eye in the sky. Access and survey any terrain with ease using various camera angles, altitudes and exact GPS coordinates. Learn everything you need to know using only precise methods and all round efficiency.
          </Card.Text>

          <Card.Text className='services-card-list'>
            <h5> USE CASES </h5>
            <ul> 
            <li><h2>- Aerial Topographical Survey</h2> </li>
            <li><h2>- DSM / DTM Models</h2> </li>
            <li><h2>- Orthophoto</h2> </li>
            <li><h2>- Infrastructure Project Monitoring</h2></li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          <NavLink to="/surveying" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>


    {/* <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
      <img className='img-fluid services-image' src={RoadSurveyPhoto} alt='Drone Geotagged Video Road Survey'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation text="ROAD SURVEY - GEOTAGGED VIDEO " color="orange"> </HeaderUnderlineAnimation></Card.Title>
          <br />
          <Card.Text className="justify-content services-card-text">
          Assess your next move with an eye in the sky. Access and survey any terrain with ease using various camera angles, altitudes and exact GPS coordinates. Learn everything you need to know using only precise methods and all round efficiency.
          </Card.Text>

          <Card.Text className='services-card-list'>
            <h5> USE CASES </h5>
            <ul> 
            <li><h2>- Aerial Topographical Survey</h2> </li>
            <li><h2>- DSM / DTM Models</h2> </li>
            <li><h2>- Orthophoto</h2> </li>
            <li><h2>- Infrastructure Project Monitoring</h2></li>
            </ul>
          </Card.Text>
          <br />
          <NavLink to="/drone-geotagged-video-road-survey" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row> */}






    </div>

<br />


    <Row>
      <Col className='homeContactText mr-auto' xs={12} lg={12} >
        <h2 className='lightHeading'>Offline Courses</h2>
        <h2 className='darkHeading'> Learn | Train | Innovate </h2><br /> 
      </Col>
    </Row>

    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <img className='img-fluid services-image' src={AerialRobotics} alt='Aerial Robotics - Junior'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>AERIAL ROBOTICS WORKSHOP</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation className='center' text="AERIAL ROBOTICS - JUNIOR" color="blue"> </HeaderUnderlineAnimation></Card.Title>
          
          <br />
          <Card.Text className="justify-content services-card-text">
          Theoretical concepts coupled with hands-on flying experience lay a strong foundation of aerial robotics. This workshop enhances the learning process for students and enables them to build, code, and fly drones. Holistic knowledge of the science of multi-rotors, dynamics of drones, along with dos and don'ts for a UAV, propels their imagination towards limitless real-world applications.
          </Card.Text>

          <Card.Text className='services-card-list'>
            {/* <h5> COURSE OUTCOMES </h5> */}
            <ul> 
            <li>Age Group : 9 to 15 years</li>
            <li> Duration : 15 hours</li>
            <li>Level : Beginner</li>
            <li>Category : Nano Drones </li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          <NavLink to="/aerial-robotics-workshop" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>


    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <img className='img-fluid services-image' src={Bootcamp} alt='Drone Bootcamp'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>DRONE CODING BOOTCAMP</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation text="CODING BOOTCAMP" color="blue"> </HeaderUnderlineAnimation></Card.Title>

          <br />
          <Card.Text className="justify-content services-card-text">
          The Drone Bootcamp opens up avenues for drone enthusiasts to tinker and innovate with Nano Drones! The intensive learning provided equips them with the technical know-how, programming techniques, and advance robotics software frameworks like ROS. Exciting hands-on experiments and the zest for exploring, make it an enriching experience in its entirety!
          </Card.Text>

          <Card.Text className='services-card-list'>
            {/* <h5> COURSE OUTCOMES </h5> */}
            <ul> 
            <li>Age Group : 16 years +</li>
            <li> Duration : 40 hours</li>
            <li>Level : Intermediate </li>
            <li>Category : Nano Drones </li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          <NavLink to="/drone-coding-bootcamp" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>
    <Row className='services-row'>
    <Col className='center' xs={12} lg={7} >
    <img className='img-fluid services-image' src={filmingPhoto} alt='Aerial Filming'></img>
    </Col>

    <Col  xs={12} lg={5} >
      <Card className='services-card'>
        <Card.Body className='services-card-body'>        
          <div className='cardText'>
          {/* <Card.Title className='services-card-title'>DRONE CODING BOOTCAMP</Card.Title> */}
          <Card.Title className='services-card-title'><HeaderUnderlineAnimation text="AERIAL FILMING COURSE" color="blue"> </HeaderUnderlineAnimation></Card.Title>

          <br />
          <Card.Text className="justify-content services-card-text">
          Learn everything about Aerial Filming from panning, reveal, pedestal, tracing and fly over shots to drone and camera knowledge, aesthetics and industry techniques. You will be trained in an all round manner to step into proffesional drone filming.
          </Card.Text>


          <Card.Text className='services-card-list'>
            {/* <h5> COURSE OUTCOMES </h5> */}
            <ul> 
            <li>Age Group : 18 years +</li>
            <li> Duration : 20 hours </li>
            <li>Level : Advance </li>
            <li>Category : Micro Drones </li>
            </ul>
          </Card.Text>
          <br />
          {/* <Button className='btn-left'> KNOW MORE </Button> */}
          <NavLink to="/aerial-filming-course" className='text-decoration-none'><ButtonAnimation text='Know More' color='black' bgColor='blue'/></NavLink>
          </div>
        </Card.Body>
      </Card>
    </Col>
    </Row>
    
   
    </Container>

    </>
  );
}

export default Services;
