import '../App.css';
import React, {useLayoutEffect} from 'react';
import './policy.css';
import {Container , Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicy = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

  return (
    <>
    <div className='policy-component'>
  
    <Container>
    <div className="max-w-4xl mx-auto p-6 text-left">

                <Row>
                <Col>
                
                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                <p className="mb-4">Effective Date: 11 March 2025</p>

                {/* <h2 className="text-2xl font-semibold mt-4">1. Introduction</h2> */}
      <p className="mb-4">
        Welcome to Drone School India. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you visit our website.
      </p>

      <h3 className="text-2xl font-semibold mt-4">1. Information We Collect</h3>
      <p className="mb-4">
        We may collect personal identification information (Name, Email Address, etc.)
        and non-personal identification information (Browser type, IP address, etc.).
      </p>

      <h3 className="text-2xl font-semibold mt-4">2. How We Use Your Information</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>To improve our website and user experience.</li>
        <li>To send periodic emails and updates.</li>
        <li>To analyze usage trends and administer the site.</li>
      </ul>

      <h3 className="text-2xl font-semibold mt-4">3. Sharing Your Information</h3>
      <p className="mb-4">
        We do not sell, trade, or rent your personal identification information to others.
        We may share information with third-party service providers to help operate our site.
      </p>

      <h3 className="text-2xl font-semibold mt-4">4. Security</h3>
      <p className="mb-4">
        We adopt appropriate security measures to protect against unauthorized access,
        alteration, disclosure, or destruction of your personal data.
      </p>

      <h3 className="text-2xl font-semibold mt-4">5. Changes to This Policy</h3>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. We encourage you to
        review this page periodically for any changes.
      </p>

      {/* <h2 className="text-2xl font-semibold mt-4">7. Contact Us</h2> */}
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us at contact@droneschoolindia.com
      </p>
        
        </Col>
        </Row>
        </div>
    </Container>

    </div>
    </>
  );
};

export default PrivacyPolicy;