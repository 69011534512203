import './App.css';
import {React, useState} from 'react';
import {NavLink} from 'react-router-dom';
import { Link } from 'react-router-dom';
import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
import Logo from './images/webp/dsi-logo.webp'; 
import './navbar.css';

function NavbarMenu() {
  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg="light" variant="light">
    <Navbar.Brand className="ml-5">
    <NavLink to="/">
      <img src={Logo} width="90"
        height="60"
        className="d-inline-block align-top"
        alt="DSI Logo"
        ></img>
      </NavLink>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto ml-auto">
        <Nav.Link><NavLink  className="nav-item" to="/">HOME </NavLink></Nav.Link>
        <Nav.Link><NavLink  className="nav-item"  to="/about">ABOUT  US</NavLink></Nav.Link>
        <Nav.Link><NavLink  className="nav-item"  to="/books">BOOK</NavLink></Nav.Link>

        <NavDropdown className="nav-item"  title="SERVICES">
          <NavDropdown.Item><NavLink  className="nav-item"  to="/aerial-cinematography">AERIAL CINEMATICS</NavLink></NavDropdown.Item>
          <NavDropdown.Item> <NavLink  className="nav-item"  to="/virtual-tours"> 360° VIRTUAL TOURS </NavLink></NavDropdown.Item>
          <NavDropdown.Item><NavLink  className="nav-item"  to="/surveying"> AERIAL SURVEYING & MAPPING  </NavLink></NavDropdown.Item>
          {/* <NavDropdown.Item><NavLink  className="nav-item"  to="/drone-geotagged-video-road-survey"> ROAD SURVEY - GEOTAGGED VIDEO </NavLink></NavDropdown.Item>        */}
        </NavDropdown>

        <NavDropdown className="nav-item"  title="COURSES">
        <NavDropdown className="nav-item"  title="ONLINE">
          <NavDropdown.Item className="nav-item" href="https://courses.droneschoolindia.com/courses/Specialization-Geotagging-Drone-Videos-for-Survey-66e7d91056027e2eb497ea35-66e7d91056027e2eb497ea35" target="_blank">
          GEOTAGGING DRONE VIDEOS FOR SURVEY
          </NavDropdown.Item>
          <NavDropdown.Item className="nav-item" href="https://courses.droneschoolindia.com/courses/Drone-Technology-for-Beginners-67a9a1bafda1f007d4497a31" target="_blank">
          DRONE TECHNOLOGY FOR BEGINNERS
          </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown className="nav-item"  title="OFFLINE">
          <NavDropdown.Item> <NavLink  className="nav-item"  to="/aerial-robotics-workshop"> AERIAL ROBOTICS - JUNIOR</NavLink></NavDropdown.Item>
          <NavDropdown.Item><NavLink  className="nav-item"  to="/drone-coding-bootcamp"> DRONE CODING BOOTCAMP </NavLink></NavDropdown.Item>
          <NavDropdown.Item><NavLink  className="nav-item"  to="/aerial-filming-course">AERIAL FILMING</NavLink></NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>

        <NavDropdown className="nav-item"  title="PORTFOLIO">
          <NavDropdown.Item><NavLink  className="nav-item"  to="/aerial-360-virtual-tour-gallery"> 360° VIRTUAL TOURS </NavLink></NavDropdown.Item>
          {/* <NavDropdown.Item><NavLink className="nav-item"  to="/real-estate-portfolio"> REAL ESTATE </NavLink></NavDropdown.Item>
          <NavDropdown.Item><NavLink className="nav-item"  to="/infra-portfolio"> INFRA</NavLink></NavDropdown.Item> */}
          <NavDropdown.Item> <NavLink  className="nav-item" to="/aerial-video-gallery"> SHOWREELS </NavLink></NavDropdown.Item>
          <NavDropdown.Item> <NavLink  className="nav-item" to="/aerial-photo-gallery"> AERIAL PHOTOGRAPHY </NavLink></NavDropdown.Item>
        </NavDropdown>

        <Nav.Link><NavLink  className="nav-item nav-contact" to="/contact"> CONTACT US </NavLink></Nav.Link>

      </Nav>
    </Navbar.Collapse>
</Navbar>
  );
}

export default NavbarMenu;
